<!--
 * @Description: 弹框对比数据
 * @Author: sunxiaodong
 * @Date: 2020-08-03 17:33:01
 * @LastEditors: ChenXueLin
 * @LastEditTime: 2022-07-01 09:21:39
-->
<template>
  <el-dialog
    v-dialogDrag
    custom-class="e6-contrast-data"
    title="信息对比"
    :width="width"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <ul class="contrast">
      <!-- <li class="contrast-item">
        <div
          class="contrast-item__column"
          v-for="(name, index) in columnTitle"
          :style="{ width: columnWidth(index) }"
          :key="`${name}-${index}`"
        >
          {{ name }}
        </div>
      </li> -->
      <!--   -->
      <li
        class="contrast-item"
        v-for="(item, _index) in dataLocal"
        :key="_index"
        :class="{ 'is-active': !item.equality }"
      >
        <div
          class="contrast-item__column"
          v-for="(column, index) in columnKeysLocal"
          :style="{ width: columnWidth(index) }"
          :key="`${column.fieldName}-${index}`"
        >
          <slot
            v-if="index !== 0 && $scopedSlots[item.fieldNameSource]"
            :name="item.fieldNameSource"
            :$index="index - 1"
            :$data="data[index - 1]"
          />
          <template v-else>
            {{ item[column.fieldName] }}
          </template>
        </div>
      </li>
    </ul>
  </el-dialog>
</template>

<script>
export default {
  name: "E6Contrast",

  props: {
    // 弹框是否显示
    dialogVisible: {
      type: Boolean,
      default: false
    },

    // dialog 的宽度
    width: {
      type: String,
      default: "800px"
    },

    // 列标题
    columnTitle: {
      type: Array,
      default() {
        return [];
      }
    },

    // 行数据 <object>[],数组的每一项是个对象
    // 并且这个对象存在 fieldName、fieldLabel属性
    // fieldLabel 表示的是需要展示字段名称，fieldName 表示的是字段的key值
    // example {fieldLabel: "姓名", fieldName: "namge"}
    rowData: {
      type: Array,
      default() {
        return [];
      }
    },
    // 需要对比的数据
    data: {
      type: Array,
      default() {
        return [];
      }
    },

    // 字段名称（第一列）的宽度，其他列均分
    firstColumnWidth: {
      type: Number,
      default: 25
    }
  },

  data() {
    return {
      columnKeysLocal: [],
      rowKeysLocal: [],
      dataLocal: []
    };
  },

  computed: {},

  watch: {
    data: {
      immediate: true,
      handler(value) {
        this.formatColumnKeys();
        this.formatData(value);
      }
    }
  },

  methods: {
    handleBeforeClose() {},

    // 对比方法，当遇到引用数据类型时，转为json对比
    contractMethod(data1, data2) {
      return JSON.stringify(data1) === JSON.stringify(data2);
    },

    // 格式化数据
    formatData(data) {
      if (!data.length) return [];
      this.dataLocal = this.rowData.reduce((total, cur) => {
        let item = {};
        let equality = true;
        let field = "";
        const columnTitle = this.columnTitle;
        for (let i = 0; i < columnTitle.length; i++) {
          if (i === 0) {
            item.fieldName = cur.fieldLabel;
            item.fieldNameSource = cur.fieldName;
          } else {
            if (data.length >= i) {
              item[`column_${i}`] = data[i - 1][cur.fieldName];
              // 此处是逻辑是数据之间的对比
              if (i === 1) {
                field = data[i - 1][cur.fieldName];
              } else {
                if (equality) {
                  equality = this.contractMethod(
                    field,
                    data[i - 1][cur.fieldName]
                  );
                }
                item.equality = equality;
              }
            }
          }
        }

        total.push(item);
        return total;
      }, []);
    },

    formatColumnKeys() {
      if (!this.columnTitle.length) return [];
      this.columnKeysLocal = this.columnTitle.map((item, index) => ({
        fieldName: index === 0 ? "fieldName" : `column_${index}`,
        fieldLabel: item
      }));
    },

    columnWidth(index) {
      if (index === 0) {
        return `${this.firstColumnWidth}%`;
      } else {
        return (
          (100 - this.firstColumnWidth) / (this.columnTitle.length - 1) + "%"
        );
      }
    },
    handleClose() {
      this.$emit("handleClose");
    }
  }
};
</script>
<style lang="scss" scoped>
ol,
ul {
  list-style: none;
}
/deep/.e6-contrast-data {
  .el-dialog__body {
    height: 500px;
    overflow: auto;
    .contrast {
      margin: 0 10px;
      padding-bottom: 15px;
      .contrast-item {
        padding: 8px 0;
        font-size: 12px;
        line-height: 18px;
        border-bottom: 1px solid #ebeef5;
        &:first-child {
          padding: 11px 0;
          color: #898c91;
          border-bottom-width: 2px;
        }
        &::before {
          content: "";
          display: table;
          clear: both;
        }
        &::after {
          content: "";
          display: table;
          clear: both;
        }
        .contrast-item__column {
          float: left;
          padding-left: 10px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          &:first-child {
            font-weight: 700;
          }
        }
      }
      .is-active {
        background-color: #fef1f1;
      }
    }
  }
}
/deep/.el-dialog {
  .el-dialog__header {
    margin-bottom: 15px;
  }
}
</style>
