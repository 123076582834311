/*
 * @Description: 可日志混入
 * @Author: LiangYiNing
 * @Date: 2021-12-17 14:53:55
 * @LastEditTime: 2022-07-15 16:39:04
 * @LastEditors: ChenXueLin
 */
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { getCompareHeader, getHeader } from "@/api";
import { printError } from "@/utils/util";
import Contrast from "@/components/Contrast";
export default {
  data() {
    return {
      loading: false,
      total: 0,
      columnData: [], //表头
      tableData: [], // 表格数据
      selectColumn: [], //对比数据
      dialogVisible: false,
      columnTitle: [1, 2, 3],
      contrastData: [], //对比信息
      rowData: [],
      dataIdFixedName: "id", // 由于不同日志接口的唯一标识字段，不一致 此项改为可配置项
      refTable: "tableList"
    };
  },
  mixins: [listPage, listPageReszie, base],
  computed: {},
  components: {
    Contrast
  },
  created() {
    this.getHeadersReq();
    this.getCompareHeaderReq();
  },
  mounted() {
    this.setTableScrollDOM();
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    cellClass(row) {
      if (row.columnIndex === 0) {
        return "DisableSelection";
      }
    },
    //获取表头
    async getHeadersReq() {
      this.loading = true;
      try {
        let res = await getHeader({ headerType: this.headerType });
        this.columnData = this.getFreezeResponse(res, {
          path: "data"
        });
        this.queryList();
      } catch (error) {
        printError(error);
      }
    },
    //获取对比表头
    async getCompareHeaderReq() {
      try {
        let res = await getCompareHeader({ headerType: this.headerType });
        this.rowData = res.data;
      } catch (error) {
        printError(error);
      }
    },
    //获取对比信息
    // async getContrastInfoReq() {
    //   try {
    //     let res = await this.getContrastInfo({
    //       ids: this.selectColumn.map(item => item.id)
    //     });
    //     this.contrastData = this.getFreezeResponse(res, {
    //       path: "data.array"
    //     });
    //   } catch (error) {
    //     printError(error);
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    //表格选中数据
    handleSelectionChange(val) {
      this.selectColumn = val;
    },
    //只能选中两个数据对比
    checkSelectable(row) {
      return (
        this.selectColumn.length < 2 ||
        this.selectColumn.findIndex(item => {
          return item[this.dataIdFixedName] == row[this.dataIdFixedName];
        }) > -1
      );
    },
    //处理对比
    handleContrast() {
      if (this.selectColumn.length < 2) {
        this.$message.warning("请选择2条数据进行对比");
        return;
      }
      this.contrastData = this.selectColumn;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
